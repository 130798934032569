<template>
  <Typography
    v-for="[
      variant,
      text,
      component,
    ] in classes"
    :key="variant"
    :component="component"
    :variant="variant"
    v-text="text"
  />
</template>

<script>
export default {
  name: 'TypographyExample',
  data() {
    return {
      classes: [
        [ 'hero', 'Hero' ],
        [ 'h1', 'Heading 1' ],
        [ 'h2', 'Heading 2' ],
        [ 'h3', 'Heading 3' ],
        [ 'subtitle1', 'Subtitle 1' ],
        [ 'subtitle2', 'Subtitle 2' ],
        [ 'subtitle3', 'Subtitle 3' ],
        [ 'body1', 'body 1' ],
        [ 'body2', 'body 2' ],
        [ 'button', 'button' ],
        [ 'h2', 'H2 Variant H3', 'h3' ],
        [ 'body1', 'Body1 Variant Span', 'span' ],
        [ 'body1', 'Body1 Variant Span', 'small' ],
      ],
    };
  },
};
</script>
