<template>
  <div class="box">
    <BaseContainer
      v-for="[
        variant,
        text,
      ] in baseContainers"
      :key="variant"
      :bg-color="variant"
      v-text="text"
    />
    <hr>
    <SectionContainer
      v-for="[
        variant,
        text,
        size,
      ] in sectionContainers"
      :key="variant"
      :bg-color="variant"
      :size="size"
    >
      <div class="slot">
        {{ text }}
      </div>
    </SectionContainer>
  </div>
</template>

<script>
import BaseContainer from '@/components/grid/BaseContainer.vue';

export default {
  name: 'ContainersExample',
  components: { BaseContainer },
  data() {
    return {
      baseContainers: [
        [ '01', 'l-baseContainer 01' ],
        [ '02', 'l-baseContainer 02' ],
        [ 'inherit', 'l-baseContainer inherit' ],
      ],
      sectionContainers: [
        [ '01', 'l-baseContainer 01 SM DEFAULT', 'sm' ],
        [ '02', 'l-baseContainer 02 MD', 'md' ],
        [ '02', 'l-baseContainer 02 LG', 'lg' ],
        [ '02', 'l-baseContainer 02 FULL', 'full' ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 20px;

  border: 1px solid white;
  background-color: cornflowerblue;
}
</style>
