<template>
  <div>
    <Tabs
      group-name="test-group"
      initial-tab="Services"
    >
      <Tab name="Services">
        <h1>What we do</h1>
      </Tab>
      <Tab name="Pricing">
        <h1>How much we do it for</h1>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tab from '@/components/common/Tabs/Tab.vue';
import Tabs from '@/components/common/Tabs/Tabs.vue';

export default {
  name: 'TabsExample',
  components: { Tab, Tabs },
  data() {
    return {
      buttons: [
        [ 'primary', 'primary' ],
        [ 'secondary', 'secondary' ],
        [ 'tertiary', 'tertiary' ],
      ],
    };
  },
};
</script>
