<template>
  <h1>Buttons</h1>
  <h2 class="separator">
    Variants
  </h2>

  <div
    class="spacer"
    v-for="key in buttons.keys"
    :key="key"
  >
    <h3>{{ key }}</h3>
    <div class="wrapper">
      <div
        class="spacer"
        v-for="variant in buttons.variants"
        :key="variant"
      >
        <BaseButton
          :color="key"
          :variant="variant"
          :btn-label="variant"
        />
      </div>
    </div>
  </div>

  <h2 class="separator">
    Colours
  </h2>
  <div class="wrapper">
    <div
      class="spacer"
      v-for="[
        color,
        text,
        variant,
        to,
      ] in buttonsColours"
      :key="color"
    >
      <BaseButton
        :color="color"
        :variant="variant"
        :btn-label="text"
        :to="Trans.i18nRoutePath(to)"
      />
    </div>
  </div>

  <h2 class="separator">
    Sizes
  </h2>
  <div class="wrapper">
    <div
      class="spacer"
      v-for="[
        color,
        text,
        variant,
        size,
      ] in buttonsSizes"
      :key="color"
    >
      <BaseButton
        :color="color"
        :variant="variant"
        :size="size"
        :btn-label="text"
      />
    </div>
  </div>
  <h2 class="separator">
    Anchors Variations
  </h2>
  <div class="wrapper">
    <div
      class="spacer"
      v-for="[
        color,
        text,
        variant,
        size,
        url,
        to,
      ] in anchors"
      :key="color"
    >
      <BaseButton
        :color="color"
        :variant="variant"
        :size="size"
        :href="url"
        :to="Trans.i18nRoutePath(to)"
        :btn-label="text"
      />
    </div>
  </div>
  <h2 class="separator">
    Icons and Variations
  </h2>

  <div
    class="spacer half"
    v-for="[
      color,
      text,
      variant,
      size,
      icon,
      iconColor,
      rightLabel
    ] in iconButtons"
    :key="color"
  >
    <BaseButton
      :color="color"
      :variant="variant"
      :size="size"
      :icon="icon"
      :icon-color="iconColor"
      :btn-label="text"
      :label-right="rightLabel"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';
import Trans from '@/mixins/translation';

export default {
  name: 'ButtonsExample',
  components: { BaseButton },
  data() {
    return {
      Trans,
      buttons: {
        keys: [ 'primary', 'secondary', 'tertiary' ],
        variants: [ 'default', 'solid', 'outlined', 'custom' ],
      },
      anchors: [
        [ 'primary', 'primary with external url', 'default', 'small', 'https://www.google.com' ],
        [ 'primary', 'primary ghost with external url', 'outlined', 'medium', 'https://www.google.com' ],
        [ 'primary', 'primary using router-link', 'solid', 'large', '', '/design-system' ],
        [ 'secondary', 'secondary using router-link', 'solid', 'medium', '', '/design-system' ],
      ],
      buttonsSizes: [
        [ 'primary', 'Small', 'solid', 'small' ],
        [ 'secondary', 'Medium (default)', 'solid', 'medium' ],
        [ 'tertiary', 'Large', 'solid', 'large' ],
      ],
      buttonsColours: [
        [ 'primary', 'primary', 'solid' ],
        [ 'secondary', 'secondary', 'solid' ],
        [ 'tertiary', 'tertiary', 'solid' ],
        [ 'muted', 'muted', 'solid' ],
      ],
      iconButtons: [
        [ 'primary', '', 'default', 'small', 'location', '', '' ],
        [ 'muted', '', 'default', 'medium', 'location', 'muted', '' ],
        [ 'primary', 'text with icon with diff color', 'default', 'small', 'location', 'muted', '' ],
        [ 'tertiary', '', 'outlined', 'small', 'location', '', '' ],
        [ 'tertiary', 'text with icon', 'default', 'medium', 'location', '', '' ],
        [ 'primary', '', 'outlined', 'medium', 'location', '', 'right label with icon' ],
        [ 'secondary', 'secondary', 'outlined', 'large', '', '', 'right label' ],
        [ 'tertiary', 'left label', 'outlined', 'medium', '', '', 'right label' ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spacer {
  margin: 5px 0;
}

.half {
  width: 50%;
}

.separator:after {
  display: block;

  width: 100%;

  content: '';

  border-bottom: 1px solid lightgray;
}
</style>
