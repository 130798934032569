<template>
  <div class="wrapper">
    <div class="menu">
      <button
        v-for="[k, v] in menu"
        :key="k"
        v-text="k"
        @click="view = v"
      />
    </div>
    <div class="body">
      <component
        :is="view"
      />
    </div>
  </div>
</template>

<script>
import ButtonsExample from '@/layouts/design-system/ButtonsExample.vue';
import CheckboxesExample from '@/layouts/design-system/CheckboxesExample.vue';
import ContainersExample from '@/layouts/design-system/ContainersExample.vue';
import TabsExample from '@/layouts/design-system/TabsExample.vue';
import TypographyExample from '@/layouts/design-system/TypographyExample.vue';

export default {
  name: 'DesignSystem',
  components: {
    ButtonsExample, ContainersExample, TabsExample, TypographyExample, CheckboxesExample,
  },
  data() {
    return {
      menu: [
        [ 'Buttons', 'ButtonsExample' ],
        [ 'Containers', 'ContainersExample' ],
        [ 'Tabs', 'TabsExample' ],
        [ 'Typography', 'TypographyExample' ],
        [ 'Checkboxes', 'CheckboxesExample' ],
      ],
      view: 'TypographyExample',
    };
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.wrapper {
  @include mx.d-flex();

  margin-top: 120px;
  margin-bottom: 60px;
}

.body {
  flex: 1;
}
</style>
